<template>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <h5><i class="fa fa-list mr-1"></i>Daftar Sub Kegiatan {{ name_asb }}</h5>
            </div>
            <div class="col-sm-3">
                 <button class="btn btn-info btn-sm mr-1" @click="set_asb('Fisik')"><i class="fa fa-list"></i> ASB Fisik</button>
                 <button class="btn btn-info btn-sm" @click="set_asb('Non Fisik')"><i class="fa fa-list"></i> ASB Non Fisik</button>
            </div>
            <div class="col-sm-3">
                <b-form-group class="">
                    <b-form-input id="smallInput" size="sm" v-model="cari" placeholder="Pencarian Sub Kegiatan" />
                </b-form-group>
            </div>
        </div> 
        <div class="row font-small-3">
            <div class="col-xl-12 col-md-6 col-12" v-for="rs in rs_sub_kegiatan.rs" v-bind:key="rs.id">
                <div class="card "> 
                    <div class="card-body  ">
                        <div class="row">
                            <div class="col-sm-6">

                                <h6 v-if="rs.kode_sub_dinas !=''">Kode Sub Dinas :<br>
                                    <b>{{ rs.kode_sub_dinas.kode_dinas }}</b></h6>
                                <h6 v-if="rs.kode_sub_dinas !=''">Nama Sub Kegiatan :</h6>
                                <p class="" v-if="rs.kode_sub_dinas !=''">{{rs.kode_sub_dinas.kode_sub_kegiatan  }}
                                </p>

                                <h6>Kode Kegiatan :<br> <b>{{ rs.kode_dinas.kode_dinas }} - {{ rs.kode_sub_kegiatan }}</b></h6>
                                <h6>Nama Sub Kegiatan :</h6>
                                <p class="">{{ rs.nama_sub_kegiatan }}</p> 
                            </div>
                            <div class="col-sm-6 mr-l  font-small-3 ">
                                <h6> Jenis ASB {{ rs.jenis_asb }}</h6>

                                  <span>Total Belanja RKA : <b class="float-right">
                                        Rp. {{ formatPrice(rs.total_belanja) }}
                                    </b>
                                </span>
                                <br> 
                                <span class="mr-2">Total  Objek : <b  >
                                        {{ formatPrice(rs.jm_objek.jm_objek) }} 
                                    </b>
                                </span>
                                <span class="mr-2 text-info">Sesuai SSH : <b  >
                                        {{ formatPrice(rs.jm_assh.sesuai) }} 
                                    </b>
                                </span>
                                <span class="text-danger">Tidak Sesuai SSH: <b>
                                        {{ formatPrice(rs.jm_assh.tidak_sesuai) }}
                                    </b>
                                </span>
                                <br>
                                <span>Total Belanja Objek : <b class="float-right">
                                       Rp.  {{ formatPrice(rs.jm_objek.total_belanja_objek) }}
                                    </b>
                                </span>  

                                <div class="row mt-1">
                                    <div class="col-md-12 col-lg-12 col-xs-12">
                                        <router-link to="/asb_kesesuaian_ssh/detail_dinas/detail_kegiatan">
                                            <button type="button" @click="detailSubKegiatan(rs)"
                                                class="btn btn-sm btn-info btn-block waves-effect waves-float waves-light"><i
                                                    class="fa fa-list mr-1"></i>Detail </button>
                                        </router-link>

                                    </div> 
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <i class="fa fa-list"></i> Total Row {{ rs_sub_kegiatan.lenght}}
            </div>
            <div class="col-6 ">
                <template>
                    <b-pagination v-model="currentPage" :total-rows=" rs_sub_kegiatan.lenght" per-page='3'
                        :align="'right'" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import Ripple from 'vue-ripple-directive'
    import {
        BFormSelect, 
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BPagination
    } from 'bootstrap-vue'

    export default {
        components: { 
            BFormSelect,
            BFormInput,
            BFormGroup,
            BRow,
            BCol,
            BPagination
        },

        data() {
            return {
                currentPage: 1,
                rows: 0,
                cari: "",
                jenis_asb : "",
                name_asb : "",
            }
        },
        mixins: [Base],

        directives: {
            Ripple,
        },
        watch: {
            cari(newData, old) {
                if (newData != NaN) {
                    this.load_data(this.cari, this.currentPage);
                }
            },
            currentPage(newData, old) {
                if (newData != NaN) {
                    this.load_data(this.cari, this.currentPage);
                }
            },
        },
        computed: {
            ...mapState({
                rs_dinas: state => state.assh.rs_dinas,
                dinas: state => state.assh.dinas,
                rs_sub_kegiatan: state => state.assh.rs_sub_kegiatan,
                sub_kegiatan: state => state.assh.sub_kegiatan,
                count_data: state => state.assh.count_data,                
                objek_belanja: state => state.assh.objek_belanja,
            }),
        },
        mounted() {
            this.load_data('', ''); 
        },
        methods: {
            ...mapMutations({
                set_rs_dinas_ssh: 'set_rs_dinas_ssh',
                set_dinas_ssh: 'dinas_ssh',
                set_rs_sub_kegiatan_ssh: "set_rs_sub_kegiatan_ssh",
                set_sub_kegiatan_ssh: "set_sub_kegiatan_ssh",
                set_count_data_ssh: 'set_count_data_ssh',
                set_objek_belanja_ssh : 'set_objek_belanja_ssh'
            }),
            set_asb(val){
                this.jenis_asb = val;
                this.name_asb = "ASB "+ val;
                this.load_data(self.cari, self.offset);
            },

            // formatPrice(value) {
            //     let val = (value / 1).toFixed(0).replace('.', ',')
            //     return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            // },
            // async belanja_objek(val) {
            //         const self = this;
            //         await axios({
            //             method: 'PUT',
            //             url: '/api/assh/sub_kegiatan/belanja_objek',
            //             data: {
            //                 id_transaksi : val.id
            //             },
            //             headers: {
            //               'Authorization': self.isAuthenticated
            //             }
            //           })
            //           .then(function (response) {
            //             self.set_objek_belanja_ssh(response.data.result); 
            //           }).catch(err => {
            //             self.pesan = err.message;
            //             self.notification('warning', "Error", err.message);
            //           });
            //       },


                  async belanja_objek(val) {
                    const self = this;
                    await axios({
                        method: 'PUT',
                        url: '/api/assh/sub_kegiatan/load_rincian_belanja',
                        data: {
                            id_transaksi : val.id
                        },
                        headers: {
                          'Authorization': self.isAuthenticated
                        }
                      })
                      .then(function (response) { 
                        self.set_objek_belanja_ssh(response.data.result); 
                      }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                      });
                  },

            detailSubKegiatan(val) {
                console.log(val);
                this.set_sub_kegiatan_ssh(val);
                this.belanja_objek(val);
            }, 

            async load_data(cari, offset) {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/assh/sub_kegiatan/load',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                            cari: cari,
                            offset: offset,
                            jenis_asb : self.jenis_asb
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_rs_sub_kegiatan_ssh(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            }, 
        }
    }
</script>

<style>

</style>